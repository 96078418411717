.ql-snow.ql-toolbar {
  display: block;
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

.ql-editor {
  min-height: 18em;
}

.ql-snow .ql-tooltip {
  left: 0 !important;
}

